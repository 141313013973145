<template>
  <section class="section">
    <b-field>
      <b-upload
        v-model="dropFiles"
        drag-drop
        expanded
        accept=".xls, .xlsx"
        :disabled="disabledUpload"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Arraste o arquivo ou clique para fazer upload</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div class="tags">
      <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
        {{file.name}}
        <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
      </span>
    </div>
    <p class="has-text-right">
      <small>
        Apenas arquivos <strong>.xls, .xlsx</strong> são permitidos
      </small>
    </p>
  </section>
</template>
<script>
import XLSX from 'xlsx'
// const makeCols = refstr => Array(XLSX.utils.decode_range(refstr).e.c + 1).fill(0).map((x, i) => ({ name: XLSX.utils.encode_col(i), key: i }))

export default {
  name: 'Upload',
  data () {
    return {
      dropFiles: null,
      data: null
    }
  },
  props: ['disabledUpload'],
  methods: {
    _file (file) {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader()
      reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result
        const wb = XLSX.read(new Uint8Array(ab), { type: 'array' })
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        /* Update state */
        this.data = data
        this.$emit('onUpdate', data)
      }
      reader.readAsArrayBuffer(file)
    },
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1)
    }
  },
  watch: {
    dropFiles (data) {
      this._file(data)
    }
  }
}
</script>
